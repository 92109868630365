// Step 1: Import React
import * as React from 'react'
import {useRef} from 'react'
import emailjs from '@emailjs/browser'
import Layout from '../components/layout'
import Seo  from '../components/seo'
import {container,
        leftHand,
        contactHeader,
        contactInfo,
        inputField,
        sendButton,  
      } from '../components/css/contact.module.css'
// Step 2: Define your component
const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    alert("Mesajınız için teşekkür ederiz. Size dönüş sağlayacağız.")
    
    emailjs.sendForm('service_mmo5w89', 'contact_form', form.current, 'tsXEAnFvdj7YDfYpQ')
      .then((result) => {
         
          console.log(result.text);
          window.location.reload(false)
      }, (error) => {
          console.log(error.text);
      });
  };
  return (
    <Layout pageTitle="İletişim">
      <div className={container}>
        <div className={leftHand}>
          <h4 className={contactHeader}>İletişim</h4>
          <h5 className={contactInfo}>Fethiye Caddesi No: 40/ 221 41300 İzmit/Kocaeli</h5>
          <h5 className={contactInfo}>info@emineyavuzmimarlik.com</h5>
        </div>
          <form ref={form} onSubmit={sendEmail}>
            <div className={inputField}>
            <label for="nameSurname">İsim Soyisim*</label>
            <input type="text" placeholder='İsim ve Soyisim giriniz' name="nameSurname"id="nameSurname"required/>
           </div>
           <div className={inputField}>
            <label for="email">Elektronik Posta*</label>
            <input type="text" placeholder='E-Posta Giriniz' name="email" id="email" required/>
           </div>
           <div className={inputField}>
            <label for="phone">Telefon Numarası</label>
            <input type="text" placeholder='Telefon Numaranızı Giriniz' name="phone" id="phone" />
           </div>
           <div className={inputField}>
            <label for="adress">Adres</label>
            <input type="text" placeholder='Adresinizi Giriniz' name="adress" id="adress" />
           </div>
           <div className={inputField} style={{gridColumn:"1/3"}}>
            <label for="subject">Konu</label>
            <input type="text"  name="subject" id="subject"/>
           </div>
           <div className={inputField} style={{gridColumn:"1/3",gridRow:"4/6"}}>
            <label for="message">Mesaj*</label>
            <textarea id="message" rows="3" placeholder='Mesajınızı Giriniz' name="message" required/>
           </div>
           <div style={{alignItems:"center",gridColumn:"1/3"}}>
           <button className={sendButton}>Gönder</button>
           </div>
          </form>   
      </div>
    </Layout>
  )
}

export const Head = () => <Seo title='İletişim' />

// Step 3: Export your component
export default Contact